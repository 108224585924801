import { HStack, typography } from "@arisechurch/design";
import { em } from "csx";
import React, { FC } from "react";
import { createUseStyles } from "react-jss";
import logoSrc from "../assets/arise-logo.svg";

const useStyles = createUseStyles({
  header: {
    ...typography.heading4,
    marginBottom: em(0.55),
  },

  directory: {
    display: "inline",
    "@media (max-width: 450px)": {
      display: "none",
    },
  },
});

export const Logo: FC = () => {
  const classes = useStyles();
  return (
    <HStack space={2} crossAxisAlign="center">
      <img src={logoSrc} alt="ARISE Church" />
      <h4 className={classes.header}>
        Staff<span className={classes.directory}> Directory</span>
      </h4>
    </HStack>
  );
};
