import memoize from "p-memoize";
import { IUser } from "../models/User";
import * as O from "fp-ts/lib/Option";
import { httpGet } from "../lib/httpGet";

export function list(): Promise<IUser[]> {
  return httpGet("/users").then(
    (r) => r.data,
    () => [],
  );
}

export function getPhoto(id: string): Promise<O.Option<Blob>> {
  return httpGet<Blob>(`/users/${id}/photo`, {
    responseType: "blob",
  }).then(
    (r) => O.some(r.data),
    () => O.none,
  );
}

export const getPhotoMemo = memoize(getPhoto);
