import React, { FC } from "react";
import { Logo } from "../components/Logo";
import { Box, HStack, Spacer, colors } from "@arisechurch/design";
import { em, percent } from "csx";
import { SearchFieldContainer } from "../containers/SearchFieldContainer";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    backgroundColor: colors.white.toString(),
    boxShadow: `0 0 3px 3px ${colors.black.fadeOut(0.9).toString()}`,
    width: percent(100),
    zIndex: 1,
  },

  input: {
    marginLeft: em(1),
    width: em(15),
  },
});

export const TopBar: FC = () => {
  // Styles
  const classNames = useStyles();

  return (
    <div className={classNames.root}>
      <Box padding={3}>
        <HStack crossAxisAlign="center">
          <Logo />
          <Spacer />
          <SearchFieldContainer className={classNames.input} />
        </HStack>
      </Box>
    </div>
  );
};
