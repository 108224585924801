import { pipe } from "fp-ts/lib/function";
import { fromPredicate, toUndefined } from "fp-ts/lib/Option";
import { atom } from "jotai";
import { list } from "./repos/userRepo";
import * as User from "./models/User";
import { filter } from "ramda";

// == FILTERS
export const searchQueryAtom = atom("");
const searchQuery = atom((get) =>
  pipe(
    get(searchQueryAtom).trim(),
    fromPredicate((s) => s.length > 0),
    toUndefined
  )
);

export const groupNamesWithAll = [...User.groupNames, "all"] as const;
export type GroupNames = typeof groupNamesWithAll[number];
export const groupFilterAtom = atom<GroupNames>("all");

// == USERS
export const usersAtom = atom(() => list());

export type IUserWithSearchFields = User.IUser & { searchTerms: string };

export const usersWithSearchTerms = atom<IUserWithSearchFields[]>((get) =>
  get(usersAtom).map((user) => ({
    ...user,
    searchTerms: User.searchTerms(user),
  }))
);

function filterUsers(
  users: IUserWithSearchFields[],
  group: GroupNames,
  query?: string
) {
  if (query) {
    const lowerQuery = query.toLowerCase();
    users = filter((u) => u.searchTerms.includes(lowerQuery), users);
  }

  if (group !== "all") {
    users = users.filter((u) => u.groupName === group);
  }

  return users;
}

export const filteredUsersAtom = atom((get) =>
  filterUsers(get(usersWithSearchTerms), get(groupFilterAtom), get(searchQuery))
);
