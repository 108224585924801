import Axios, { AxiosError, AxiosRequestConfig } from "axios";

export const httpGet = <T = any>(
  url: string,
  config: AxiosRequestConfig = {}
) =>
  Axios.get<T>(url, {
    withCredentials: true,
    ...config,
  }).catch((e: AxiosError) => {
    if (e.response?.status === 401) {
      window.location.href = "/auth/microsoft";
    }
    throw e;
  });
