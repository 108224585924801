import React, { FC } from "react";
import {
  Card,
  Box,
  VStack,
  typography,
  colors,
  Spacer,
  classes,
} from "@arisechurch/design";
import * as User from "../models/User";
import { rem, percent, em } from "csx";
import { Email, Phone, PersonAdd, LocationOn, Group } from "@material-ui/icons";
import { Avatar } from "./Avatar";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    width: rem(20),
  },

  header: {
    ...typography.heading4,
    margin: 0,
  },

  subheader: {
    color: colors.lightText.toString(),
    fontSize: rem(1),
    margin: 0,
    overflow: "hidden",
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: percent(100),
  },

  group: {
    color: colors.lightText.toString(),
    fontSize: rem(0.8),
    fontWeight: typography.semibold,
    textTransform: "uppercase",
  },

  link: {
    alignItems: "center",
    color: colors.secondary.toString(),
    cursor: "pointer",
    display: "flex",
    fontWeight: typography.semibold,
    flexDirection: "row",
    justifyContent: "flex-start",
    overflow: "hidden",
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: percent(100),
  },

  inactiveLink: {
    color: colors.lightText.toString(),
    cursor: "normal",
  },

  icon: {
    color: colors.lightText.toString(),
    fontSize: em(1),
  },
});

export interface IUserCardProps {
  user: User.IUser;
  avatarSrc?: string;
  onClickLocation?: () => void;
  onClickDepartment?: () => void;
  onSave?: () => void;
}

const Link: FC<{
  href?: string;
  icon: React.ReactNode;
  onClick?: () => void;
}> = ({ href, icon, onClick, children }) => {
  const classNames = useStyles();
  const Tag = href ? "a" : "span";
  return (
    <Tag
      href={href}
      className={classes(classNames.link, !children && classNames.inactiveLink)}
      onClick={onClick}
    >
      {icon}
      <Spacer horizontal={1} />
      {children ? children : <>&mdash;</>}
    </Tag>
  );
};

export const UserCard: FC<IUserCardProps> = ({
  user,
  avatarSrc,
  onSave,
  onClickDepartment,
  onClickLocation,
}) => {
  const classNames = useStyles();
  return (
    <Card className={classNames.root}>
      <Box padding={4}>
        <VStack crossAxisAlign="center" space={4}>
          <VStack style={{ width: "100%" }} crossAxisAlign="center" space={3}>
            <Avatar user={user} src={avatarSrc} />
            <VStack style={{ width: "100%" }} space={1} crossAxisAlign="center">
              <h4 className={classNames.header}>{user.displayName}</h4>
              <h5 className={classNames.group}>
                {User.expandedGroupName(user)}
              </h5>
              <h5 className={classNames.subheader}>{User.title(user)}</h5>
            </VStack>
          </VStack>

          <VStack style={{ width: percent(100) }} space={2}>
            <Link
              icon={<Group className={classNames.icon} />}
              onClick={onClickDepartment}
            >
              {user.department}
            </Link>
            <Link
              icon={<LocationOn className={classNames.icon} />}
              onClick={onClickLocation}
            >
              {user.officeLocation}
            </Link>
            {user.mobilePhone ? (
              <Link
                icon={<Phone className={classNames.icon} />}
                href={`tel:${User.normalizePhoneNumber(user.mobilePhone)}`}
              >
                {User.normalizePhoneNumber(user.mobilePhone)}
              </Link>
            ) : (
              <Link icon={<Phone className={classNames.icon} />} />
            )}
            <Link
              icon={<Email className={classNames.icon} />}
              href={`mailto:${user.mail}`}
            >
              {user.mail}
            </Link>
            <span className={classNames.link} onClick={onSave}>
              <PersonAdd className={classNames.icon} />
              <Spacer horizontal={1} />
              Save to contacts
            </span>
          </VStack>
        </VStack>
      </Box>
    </Card>
  );
};
