import { useAtom } from "jotai";
import React, { ChangeEvent, FC, useCallback } from "react";
import { searchQueryAtom } from "../atoms";
import { SearchField } from "../components/SearchField";

export interface ISearchFieldContainerProps {
  className?: string;
}

export const SearchFieldContainer: FC<ISearchFieldContainerProps> = ({
  className,
}) => {
  const [query, setQuery] = useAtom(searchQueryAtom);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    },
    [setQuery]
  );

  const onClear = useCallback(() => {
    setQuery("");
  }, [setQuery]);

  return (
    <SearchField
      className={className}
      value={query}
      onChange={onChange}
      onClear={onClear}
    />
  );
};
