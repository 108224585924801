import React from "react";
import ReactDOM from "react-dom";
import { AppRouter } from "./router";
import { Providers } from "@arisechurch/design";
import { setupPage } from "csstips";
import { register } from "./serviceWorker";
import PwaInstallIos from "react-ios-pwa-prompt";

import "@arisechurch/design/src/global.css";
require("./index.css");

setupPage("#root");

register();

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <AppRouter />
    </Providers>

    <PwaInstallIos />
  </React.StrictMode>,
  document.getElementById("root")
);
