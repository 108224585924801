import React, { FC } from "react";
import { IUser } from "../models/User";
import { UserCardContainer } from "../containers/UserCardContainer";
import { VirtuosoGrid, VirtuosoGridHandle } from "react-virtuoso";
import { stylesheet } from "typestyle";
import { space } from "@arisechurch/design";
import { border } from "csx";

const styles = stylesheet({
  list: {
    borderBottom: border({
      color: "transparent",
      style: "solid",
      width: space.space4,
    }),
    borderTop: border({
      color: "transparent",
      style: "solid",
      width: space.space4,
    }),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },

  item: {
    alignContent: "stretch",
    padding: space.space2,
  },
});

export interface IUserListProps {
  users: IUser[];
}

export const UserList: FC<IUserListProps> = ({ users }) => {
  const ref = React.useRef<VirtuosoGridHandle>(null);

  // Scroll to top when content changes
  React.useEffect(() => {
    if (!ref.current) return;

    ref.current.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, [ref, users.length]);

  return (
    <VirtuosoGrid
      ref={ref}
      listClassName={styles.list}
      itemClassName={styles.item}
      totalCount={users.length}
      overscan={8}
      computeItemKey={(index) => users[index].id}
      itemContent={(index) => <UserCardContainerMemo user={users[index]} />}
    />
  );
};

const UserCardContainerMemo = React.memo(
  UserCardContainer,
  (prev, next) => prev.user.id === next.user.id
);
