import { colors, Spinner } from "@arisechurch/design";
import { useAtomValue } from "jotai/utils";
import React, { Suspense } from "react";
import { createUseStyles } from "react-jss";
import { filteredUsersAtom } from "../atoms";
import { UserList } from "../components/UserList";

const useStyles = createUseStyles({
  loading: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
});

export const UserListContainer = () => (
  <Suspense fallback={<Loading />}>
    <UserListWrap />
  </Suspense>
);

const Loading = () => {
  const classNames = useStyles();
  return (
    <div className={classNames.loading}>
      <Spinner color={colors.lightText} />
    </div>
  );
};

const UserListWrap = () => <UserList users={useAtomValue(filteredUsersAtom)} />;
