import { getPhotoMemo } from "../repos/userRepo";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { blobToBase64, download, blobToFile } from "../lib/blob";
import VCard from "vcards-js";
import PhoneNumber from "awesome-phonenumber";

export const groupNames = ["staff", "ministry_school"] as const;

export interface IUser {
  "@odata.type": string;
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  jobTitle: string | null;
  mail: string;
  mobilePhone: string | null;
  officeLocation: string | null;
  userPrincipalName: string;
  department: string | null;
  interests: string[] | null;
  groupName: typeof groupNames[number];
}

export type vCard = ReturnType<typeof VCard>;

export const fullName = (u: IUser) =>
  [u.givenName, u.surname].filter((s) => !!s).join(" ");

export function normalizePhoneNumber(number: string) {
  const n = new PhoneNumber(number, "NZ");
  return n.getNumber("e164");
}

export const maybeGetBase64Photo = (userID: string) =>
  getPhotoMemo(userID).then((b) =>
    pipe(
      b,
      O.fold(
        () => Promise.resolve<O.Option<string>>(O.none),
        (b) => blobToBase64(b).then(O.some)
      )
    )
  );

export const createVCard = (user: IUser) =>
  maybeGetBase64Photo(user.id).then((s) =>
    pipe(
      s,
      O.fold(
        () => VCard(),
        (s) => {
          const v = VCard();
          v.photo.embedFromString(s, "image/jpeg");
          return v;
        }
      ),
      (v) => {
        v.firstName = user.givenName;
        v.lastName = user.surname;
        v.workEmail = user.mail;
        if (user.mobilePhone)
          v.cellPhone = normalizePhoneNumber(user.mobilePhone);
        v.organization = "ARISE Church";
        if (user.officeLocation) v.role = user.officeLocation;
        if (user.jobTitle) v.title = user.jobTitle;
        return v;
      }
    )
  );

export const vcardToBlob = (v: vCard) =>
  new Blob([v.getFormattedString()], { type: "text/vcard" });

export const downloadVCard = (u: IUser) =>
  createVCard(u)
    .then(vcardToBlob)
    .then((b) => blobToFile(b, `${fullName(u)}.vcf`))
    .then((f) => download(f));

export const title = (u: IUser) =>
  u.jobTitle ? u.jobTitle : expandedGroupName(u);

export const expandedGroupName = (u: IUser) =>
  u.groupName === "staff" ? "Staff" : "Ministry School Student";

export const searchTerms = (u: IUser) =>
  [u.displayName, u.officeLocation, u.jobTitle, u.department]
    .filter((s) => !!s)
    .join(" ")
    .toLowerCase();

export const avatarSrc = (u: IUser) => `/users/${u.id}/photo`;
