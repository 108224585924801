import { useUpdateAtom } from "jotai/utils";
import React, { FC, useCallback } from "react";
import { searchQueryAtom } from "../atoms";
import { UserCard } from "../components/UserCard";
import { avatarSrc, downloadVCard, IUser } from "../models/User";

export interface IUserCardContainerProps {
  user: IUser;
}

export const UserCardContainer: FC<IUserCardContainerProps> = ({ user }) => {
  const setSearchQuery = useUpdateAtom(searchQueryAtom);

  // onSave
  const onSave = useCallback(() => {
    downloadVCard(user);
  }, [user]);

  // onClickDepartment
  const onClickDepartment = useCallback(() => {
    if (!user.department) return;
    setSearchQuery(user.department);
  }, [setSearchQuery, user.department]);

  // onClickLocation
  const onClickLocation = useCallback(() => {
    if (!user.officeLocation) return;
    setSearchQuery(user.officeLocation);
  }, [setSearchQuery, user.officeLocation]);

  return (
    <UserCard
      user={user}
      avatarSrc={avatarSrc(user)}
      onClickDepartment={onClickDepartment}
      onClickLocation={onClickLocation}
      onSave={onSave}
    />
  );
};
