import React, { FC, useState } from "react";
import { IUser } from "../models/User";
import { colors } from "@arisechurch/design";
import { em } from "csx";
import { createUseStyles } from "react-jss";

export interface IAvatarProps {
  user: IUser;
  src?: string;
}

const useStyles = createUseStyles({
  root: {
    borderRadius: em(3.5),
    display: "block",
    height: em(7),
    width: em(7),
  },

  placeholder: {
    alignItems: "center",
    backgroundColor: colors.disabledBackground.toString(),
    borderRadius: em(3.5),
    color: colors.white.toString(),
    display: "flex",
    height: em(7),
    justifyContent: "center",
    width: em(7),

    "& > span": {
      fontSize: em(2.5),
      fontWeight: 600,
    },
  },
});

export const Avatar: FC<IAvatarProps> = ({ user, src }) => {
  const [placeholder, setPlaceholder] = useState(!src);
  const classNames = useStyles();

  return placeholder ? (
    <div className={classNames.placeholder}>
      <span>{`${user.givenName[0] || ""}${user.surname[0] || ""}`}</span>
    </div>
  ) : (
    <img
      className={classNames.root}
      src={src}
      alt={user.displayName}
      onError={() => setPlaceholder(true)}
    />
  );
};
