import { VStack } from "@arisechurch/design";
import { percent } from "csx";
import { useUpdateAtom } from "jotai/utils";
import React, { useEffect } from "react";
import {
  groupFilterAtom,
  GroupNames,
  groupNamesWithAll,
  searchQueryAtom,
} from "../atoms";
import { TopBar } from "../components/TopBar";
import { BottomNavBar } from "../containers/BottomNavBar";
import { UserListContainer } from "../containers/UserListContainer";

export const Home = () => {
  // Maybe populate search query
  const setSearchQuery = useUpdateAtom(searchQueryAtom);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const query = params.get("query");
    setSearchQuery(query || "");
  }, [setSearchQuery]);

  // Maybe set group name
  const setGroupName = useUpdateAtom(groupFilterAtom);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const group = params.get("group") as GroupNames;
    if (groupNamesWithAll.includes(group)) {
      setGroupName(group);
    }
  }, [setGroupName]);

  return (
    <VStack
      style={{
        alignItems: "stretch",
        height: percent(100),
      }}
    >
      <TopBar />
      <UserListContainer />
      <BottomNavBar />
    </VStack>
  );
};
