import React, { FC, ChangeEvent } from "react";
import { TextField } from "@arisechurch/design";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export interface ISearchFieldProps {
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

export const SearchField: FC<ISearchFieldProps> = ({
  className,
  value,
  onChange,
  onClear,
}) => {
  return (
    <TextField
      className={className}
      size="small"
      label="Search"
      placeholder="Search name, team or role"
      onChange={onChange}
      value={value}
      InputProps={{
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton onClick={onClear} edge="end">
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
