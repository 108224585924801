import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { Group, Person, School } from "@material-ui/icons";
import { useAtom } from "jotai";
import React from "react";
import { groupFilterAtom, GroupNames } from "../atoms";

export const BottomNavBar: React.FC = () => {
  const [group, setGroup] = useAtom(groupFilterAtom);

  const onChange = React.useCallback(
    (_e, newValue: GroupNames) => {
      setGroup(newValue);
    },
    [setGroup]
  );

  return (
    <AppBar position="relative">
      <BottomNavigation value={group} onChange={onChange} showLabels>
        <BottomNavigationAction label="All" value="all" icon={<Group />} />
        <BottomNavigationAction label="Staff" value="staff" icon={<Person />} />
        <BottomNavigationAction
          label="Students"
          value="ministry_school"
          icon={<School />}
        />
      </BottomNavigation>
    </AppBar>
  );
};
