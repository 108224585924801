import { saveAs } from "file-saver";

export function blobToDataURL(b: Blob) {
  const reader = new FileReader();
  reader.readAsDataURL(b);
  return new Promise<string>((resolve) => {
    reader.onloadend = () => resolve(reader.result as string);
  });
}

export const blobToBase64 = (b: Blob) =>
  blobToDataURL(b).then((s) => s.substr(s.indexOf(",") + 1));

export const blobToFile = (b: Blob, filename: string) =>
  new File([b], filename);

export const download = (b: Blob | File) => saveAs(b);
